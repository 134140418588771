// search bar
// document.addEventListener("turbolinks:load", function() {
//
//   $input = $('*[data-behavior="autocomplete"]');
//
//   var options = {
//     url: function(phrase) {
//       return "/portals/search.json?q=" + phrase;
//     },
//     getValue: "name",
//     template: {
//       type: "links",
//       fields: {
//           link: "link"
//       }
//     },
//   };
//
//   $input.easyAutocomplete(options);
//
// });



// search bar
document.addEventListener("turbolinks:load", function() {

  $input = $('*[data-behavior="autocomplete"]');

  var options = {
    url: function(phrase) {
      return "/portals/search.json?q=" + phrase;
    },
    getValue: "name",
    template: {
      type: "links",
      fields: {
          link: "link"
      }
    },
  };
  
  $input.easyAutocomplete(options);

});


// $(document).on('turbolinks:load', function() {
// $("#search_bar").autocomplete({
//     source: "values.json",
//     minLength: 2,
//     select: function(event, ui) {
//         $("#search_bar").val(ui.item.label);
//         $("#searchForm").submit(); }
// });
// });


// document.addEventListener("turbolinks:load", function() {
//
//   $("#search_bar").autocomplete({
//       source: "values.json",
//       minLength: 1,
//       select: function(event, ui) {
//           $("#search_bar").val(ui.item.label);
//           $("#search_bar").submit(); }
//   });
//
// });

//
// document.addEventListener("turbolinks:load", function() {
//
//   $( ".selector" ).autocomplete({
//       select: function(event, ui) {
//           $('#search_bar').submit();
//       }
//   });
//
//
//   // $('#search_bar').autocomplete({
//   //    source: $('#search_bar').data('autocomplete-data'),
//   //    select: function(event, ui) {
//   //          $(this).val(ui.item.value);
//   //          $(this).parents("form").submit();  // this will submit the form.
//   //    }
//   // });
//
// });





// $( ".selector" ).autocomplete({
//     select: function(event, ui) {
//         $('#theForm').submit();
//     }
// });


//
// var options = {
//   data: ["Superman", "Wonder Woman", "Iron Man", "Batman", "Catwoman"],
//   list: {
//
//     onSelectItemEvent: function() {
//       var index = $("#function-index").getSelectedItemIndex();
//
//       $("#index-holder").val(index).trigger("change");
//     }
//   }
// };
//
// $("#function-index").easyAutocomplete(options);